import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/BusinessPhoneSystem.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Conference Calls",
    paragraph: "Host meetings and communicate seamlessly. ",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Call Recording",
    paragraph: "Keep customers happy by improving service.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Hold Music",
    paragraph: "Entertain your customers and show them they matter. ",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "HQ Audio",
    paragraph: "Converse easily with no glitches.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Digital Receptionist ",
    paragraph: "Virtually manage your calls and enquiries.  ",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Voicemail",
    paragraph: "Receive and respond to all messages with ease.",
  },
];

export const HeaderData = {
  src: Internet,
  subtitle: "Communicate With Ease",
  title: "Business Phone System",
  paragraph: "Unify your business with a premium phone system ",
  to: Product_Quote_Routes.BusinessPhoneSystem,
};

export const DescriptionContent = {
  title: "Why Do You Need a Business Phone System?",
  paragraph:
    "It makes your internal and external communications efficient. It helps your company to grow. And it makes your operations seamless. That’s why premium a business phone system is crucial for success. ",
  to: Product_Quote_Routes.BusinessPhoneSystem,
  profitPoint1: "Reduced phone bills",
  profitPoint2: "Efficient communication",
  profitPoint3: "Increased customer service",
  profitPoint4: "Fewer maintenance fees",
};

export const FAQS = [
  {
    title: "What can a phone system do for my business?",
    answer:
      "A business phone system allows you to use multiple lines, hold music, voicemail, call monitoring, and much more. ",
  },
  {
    title: "What is Voice over Internet Protocol?",
    answer:
      "VoIP is a phone system that makes and receives calls using an internet connection rather than a phone line. ",
  },
  {
    title: "How much does a business phone system cost?",
    answer:
      "Prices vary depending on your requirements. Fill out our questionnaire and Digital Comparison will find you the best prices according to what you need. ",
  },
  {
    title: "What is a hosted phone system?",
    answer:
      "A hosted phone system allows the business to own the telephones while the hosting supplier owns the backend equipment.",
  },
  {
    title: "How long does it take to get a business phone system?",
    answer: "This depends on supplier. Usually, it takes a few weeks.",
  },
  {
    title: "How does a phone system save my business money?",
    answer:
      "A unified phone system from a single provider can help you save on telephone bills and maintenance costs.",
  },
  {
    title: "How can Digital Comparison get me a business phone system?",
    answer:
      "We connect you with the top providers of phone system services and equipment. You compare the offers until you find your ideal solution.",
  },
  {
    title: "Which business phone system provider is the best?",
    answer:
      "All our supply partners are qualified industry leaders. You can choose the right one for you based on your unique needs. ",
  },
];
